import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { TemporaryLocationResponse } from "@/api/temporary-locations/types/Responses";
import { AxiosResponse } from "axios";
import { CreateTemporaryLocationRequest } from "@/api/temporary-locations/types/Requests";

export default class TemporaryLocationClient extends AbstractClient {
  /**
   * Process get temporary locations by source class request
   * @param schoolClassId source school class ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getTemporaryLocationsBySourceClass(schoolClassId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<TemporaryLocationResponse>> {
    return AbstractClient.get("/api/temporary-locations/by-source-class/" + schoolClassId, pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(TemporaryLocationResponse, response.data));
  }

  /**
   * Perform get temporary locations by target class request
   * @param schoolClassId target school class ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getTemporaryLocationsByTargetClass(schoolClassId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<TemporaryLocationResponse>> {
    return AbstractClient.get("/api/temporary-locations/by-target-class/" + schoolClassId, pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(TemporaryLocationResponse, response.data));
  }

  /**
   * Perform create temporary location request
   * @param request create temporary location request
   */
  static createTemporaryLocation(request: CreateTemporaryLocationRequest): Promise<TemporaryLocationResponse[]> {
    return AbstractClient.post("/api/temporary-locations", request)
      .then((response) => response.data.map((item: any) => new TemporaryLocationResponse(item)));
  }

  /**
   * Perform accept temporary location request
   * @param temporaryLocationId temporary location ID
   */
  static acceptTemporaryLocation(temporaryLocationId: number): Promise<TemporaryLocationResponse> {
    return AbstractClient.post("/api/temporary-locations/" + temporaryLocationId + "/accept")
      .then((response) => new TemporaryLocationResponse(response.data));
  }

  /**
   * Perform revoke temporary location request
   * @param temporaryLocationId temporary location ID
   */
  static revokeTemporaryLocation(temporaryLocationId: number): Promise<TemporaryLocationResponse> {
    return AbstractClient.post("/api/temporary-locations/" + temporaryLocationId + "/revoke")
      .then((response) => new TemporaryLocationResponse(response.data));
  }

  /**
   * Perform delete temporary location request
   * @param temporaryLocationId temporary location ID
   */
  static deleteTemporaryLocation(temporaryLocationId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/temporary-locations/" + temporaryLocationId);
  }
}
