






















































import ChildAttendanceClient from "@/api/child/ChildAttendanceClient";
import ChildStatus from "@/enums/ChildStatus";
import Vue, { PropType } from "vue";
import { ChildAttendanceResponse, ChildResponse, ChildStatusResponse } from "@/api/child/types/Responses";
import { TranslateResult } from "vue-i18n";
import { AbstractEventResponse } from "@/api/events/types/Responses";
import EventClient from "@/api/events/EventClient";
import { AllItems } from "@/api/AbstractClient";
import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import moment from "moment";
import EventType from "@/enums/EventType";

export default Vue.extend({
  name: "BasicInfoWidget",
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
    vertical: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    // loading
    isBusyEvents: false,
    isBusyAttendance: false,
    // events
    events: [] as AbstractEventResponse[],
    attendance: null as ChildAttendanceResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyEvents || this.isBusyAttendance;
    },
    childCameEvent(): AbstractEventResponse | null {
      const event = this.events.find((event: AbstractEventResponse): boolean => event.type === EventType.CHILD_CAME);
      return event !== undefined ? event : null;
    },
    childLeftEvent(): AbstractEventResponse | null {
      const event = this.events.find((event: AbstractEventResponse): boolean => event.type === EventType.CHILD_LEFT);
      return event !== undefined ? event : null;
    },
    childIsPickedUpEvent(): AbstractEventResponse | null {
      const event = this.events.find((event: AbstractEventResponse): boolean => event.type === EventType.CHILD_IS_PICKED_UP);
      return event !== undefined ? event : null;
    },
    childLeavesAloneEvent(): AbstractEventResponse | null {
      const event = this.events.find((event: AbstractEventResponse): boolean => event.type === EventType.CHILD_LEAVES_ALONE);
      return event !== undefined ? event : null;
    },
    startTitle(): TranslateResult {
      return this.$t("children.arrived");
    },
    startTime(): string {
      const childCameEvent = this.childCameEvent;
      return childCameEvent ? childCameEvent.datetime.format("HH:mm") : "-";
    },
    endTitle(): TranslateResult {
      return this.childLeftEvent ? this.$t("children.left") : this.$t("children.usually_leaves");
    },
    endTime(): string {
      const childLeftEvent = this.childLeftEvent;
      if (childLeftEvent) {
        return childLeftEvent.datetime.format("HH:mm");
      }

      const childLeavesAlone = this.childLeavesAloneEvent;
      if (childLeavesAlone) {
        return childLeavesAlone.datetime.format("HH:mm");
      }

      const childIsPickedUpEvent = this.childIsPickedUpEvent;
      return childIsPickedUpEvent ? childIsPickedUpEvent.datetime.format("HH:mm") : "-";
    },
    childStatusName(): string {
      return this.attendance?.child_status.name ?? "";
    },
    childStatusDescription(): string {
      return this.attendance?.child_status.description ?? "";
    },
    childStatusColor(): string {
      if (this.attendance === null) {
        return "";
      }
      switch (this.attendance.child_status.id) {
        case ChildStatus.CHILD_APOLOGISED:
          return "info";
        case ChildStatus.CHILD_DOES_NOT_ARRIVE_AT_ALL:
          return "info";
        case ChildStatus.CHILD_DOES_NOT_ARRIVE_YET:
          return "warning";
        case ChildStatus.CHILD_IN_CLASS:
          return "success";
        case ChildStatus.CHILD_LEFT:
          return "info";
        case ChildStatus.CHILD_WAITING_FOR_TEMPORARY_LOCATION_ACCEPTANCE:
          return "warning";
        case ChildStatus.UNKNOWN:
          return "secondary";
        default:
          throw "Invalid child status";
      }
    },
    childStatusIcon(): string {
      if (this.attendance === null) {
        return "";
      }
      switch (this.attendance.child_status.id) {
        case ChildStatus.CHILD_APOLOGISED:
          return "mdi-account-cancel";
        case ChildStatus.CHILD_DOES_NOT_ARRIVE_AT_ALL:
          return "mdi-home";
        case ChildStatus.CHILD_DOES_NOT_ARRIVE_YET:
          return "mdi-account-clock";
        case ChildStatus.CHILD_IN_CLASS:
          return "mdi-account-check";
        case ChildStatus.CHILD_LEFT:
          return "mdi-account-arrow-right";
        case ChildStatus.CHILD_WAITING_FOR_TEMPORARY_LOCATION_ACCEPTANCE:
          return "mdi-account-clock";
        case ChildStatus.UNKNOWN:
          return "mdi-help-circle";
        default:
          throw "Invalid child status";
      }
    },
  },
  methods: {
    fetchAttendance(): void {
      this.isBusyAttendance = true;
      ChildAttendanceClient.getChildAttendance(this.schoolClass.id, this.child.id, moment())
        .then((response) => {
          this.attendance = response;
        })
        .finally(() => {
          this.isBusyAttendance = false;
        });
    },
    fetchEvents(): void {
      const filter = "eq:date:\"" + moment().set("hours", 0).set("minutes", 0).set("seconds", 0).format() + "\"";
      this.isBusyEvents = true;
      EventClient.getEvents(this.schoolClass.id, this.child.id, new AllItems(filter))
        .then((response) => {
          this.events = response.items;
        })
        .finally(() => {
          this.isBusyEvents = false;
        });
    },
  },
  created(): void {
    this.fetchEvents();
    this.fetchAttendance();
  },
});
