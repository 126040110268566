enum ChildStatus {
  CHILD_APOLOGISED = "CHILD_APOLOGISED",
  CHILD_DOES_NOT_ARRIVE_AT_ALL = "CHILD_DOES_NOT_ARRIVE_AT_ALL",
  CHILD_DOES_NOT_ARRIVE_YET = "CHILD_DOES_NOT_ARRIVE_YET",
  CHILD_IN_CLASS = "CHILD_IN_CLASS",
  CHILD_LEFT = "CHILD_LEFT",
  CHILD_WAITING_FOR_TEMPORARY_LOCATION_ACCEPTANCE = "CHILD_WAITING_FOR_TEMPORARY_LOCATION_ACCEPTANCE",
  UNKNOWN = "UNKNOWN"
}

export default ChildStatus;
