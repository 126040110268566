import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { ChildAttendanceResponse } from "@/api/child/types/Responses";
import { MarkChildAsMissingRequest, MarkChildAsPresentRequest } from "@/api/child/types/Requests";
import { AxiosResponse } from "axios";
import moment from "moment";

export default class ChildAttendanceClient extends AbstractClient {
  /**
   * Perform get child attendance request
   * @param schoolClassId school class ID
   * @param childId child ID
   * @param date date
   */
  static getChildAttendance(schoolClassId: number, childId: number, date: moment.Moment): Promise<ChildAttendanceResponse> {
    return AbstractClient.get("/api/children/by-class/" + schoolClassId + "/attendance/" + date.format("YYYY-MM-DD") + "/by-child/" + childId)
      .then((response) => new ChildAttendanceResponse(response.data));
  }

  /**
   * Perform get children attendance request
   * @param schoolClassId school class ID
   * @param date date
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getChildrenAttendance(schoolClassId: number, date: moment.Moment, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<ChildAttendanceResponse>> {
    return AbstractClient.get("/api/children/by-class/" + schoolClassId + "/attendance/" + date.format("YYYY-MM-DD"), pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(ChildAttendanceResponse, response.data));
  }

  /**
   * Perform mark child as present request
   * @param childId child ID
   * @param date date
   * @param request mark child as present request
   */
  static markChildAsPresent(childId: number, date: moment.Moment, request: MarkChildAsPresentRequest): Promise<AxiosResponse> {
    return AbstractClient.post("/api/children/" + childId + "/attendance/" + date.format("YYYY-MM-DD") + "/mark-as-present", request);
  }

  /**
   * Perform mark child as missing request
   * @param childId child ID
   * @param date date
   * @param request mark child as missing request
   */
  static markChildAsMissing(childId: number, date: moment.Moment, request: MarkChildAsMissingRequest): Promise<AxiosResponse> {
    return AbstractClient.post("/api/children/" + childId + "/attendance/" + date.format("YYYY-MM-DD") + "/mark-as-missing", request);
  }
}
