var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":_vm.onPreviousDay}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateFormatted,"label":_vm.$t('children.date'),"prepend-inner-icon":"mdi-calendar","readonly":"","filled":"","rounded":"","single-line":"","dense":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePickerDialog),callback:function ($$v) {_vm.datePickerDialog=$$v},expression:"datePickerDialog"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.datePickerDialog = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-btn',{staticClass:"ml-1",attrs:{"icon":""},on:{"click":_vm.onNextDay}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('data-table',{ref:"table",attrs:{"client":_vm.client,"client-function":_vm.clientFunction,"client-function-parameters":_vm.clientFunctionParameters,"headers":_vm.headers,"actions":_vm.actions},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.child.first_name)+" ")]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.child.last_name)+" ")]}},{key:"item.birthdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.child.birthdate.format("LL"))+" ")]}},{key:"item.child_status",fn:function(ref){
var item = ref.item;
return [(item.child_status)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticStyle:{"cursor":"help"},attrs:{"color":_vm.childStatusColor(item.child_status)}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.childStatusIcon(item.child_status))+" ")]),_vm._v(" "+_vm._s(item.child_status.name)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.child_status.description))])])]:_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.childDialog),callback:function ($$v) {_vm.childDialog=$$v},expression:"childDialog"}},[(_vm.childDialog && _vm.child !== null)?_c('child-detail',{attrs:{"school-class":_vm.schoolClass,"child":_vm.child},on:{"onClose":function($event){_vm.childDialog = false; _vm.child = null}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }