






















































































import BasicInfoWidget from "@/components/child/widget/BasicInfoWidget.vue";
import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import SmallEventsCalendar from "@/components/events/calendar/SmallEventsCalendar.vue";
import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import AuthorizedPersonsList from "@/components/family/misc/AuthorizedPersonsList.vue";
import ChildrenList from "@/components/family/misc/ChildrenList.vue";
import { FamilyResponse } from "@/api/family/types/Responses";
import FamilyClient from "@/api/family/FamilyClient";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";

export default Vue.extend({
  name: "ChildDetail",
  components: { BasicInfoWidget, PageSubtitle, ChildrenList, AuthorizedPersonsList, SmallEventsCalendar },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyFamily: false,
    // data
    family: null as FamilyResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return false;
    },
  },
  methods: {
    fetchFamily(): void {
      this.isBusyFamily = true;
      FamilyClient.getFamily(this.child.family_id)
        .then((response) => {
          this.family = response;
        })
        .finally(() => {
          this.isBusyFamily = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
  created(): void {
    this.fetchFamily();
  },
});
