




















































































import AbstractClient from "@/api/AbstractClient";
import ChildAttendanceClient from "@/api/child/ChildAttendanceClient";
import { MarkChildAsMissingRequest, MarkChildAsPresentRequest } from "@/api/child/types/Requests";
import { ChildAttendanceResponse, ChildResponse, ChildStatusResponse } from "@/api/child/types/Responses";
import { SchoolClassMinimalResponse } from "@/api/school-class/types/Responses";
import ChildDetail from "@/components/child/misc/ChildDetail.vue";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import ChildStatus from "@/enums/ChildStatus";
import { AxiosResponse } from "axios";
import moment from "moment";
import Vue, { PropType } from "vue";
import _ from "lodash";

export default Vue.extend({
  name: "ChildrenAttendanceTable",
  components: { ChildDetail, DataTable },
  props: {
    schoolClass: {
      type: Object as PropType<SchoolClassMinimalResponse>,
      required: true,
    },
  },
  data: () => ({
    // date filter
    date: moment().set("hours", 0).set("minutes", 0).set("seconds", 0).format() as string,
    // dialogs
    datePickerDialog: false,
    childDialog: false,
    // selected child
    child: null as ChildResponse | null,
  }),
  watch: {
    date() {
      this.$nextTick(() => {
        this.refresh();
      });
    },
  },
  computed: {
    dateFormatted(): string {
      return moment(this.date).format("LL");
    },
    client(): AbstractClient {
      return new ChildAttendanceClient();
    },
    clientFunction(): any {
      return ChildAttendanceClient.getChildrenAttendance;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolClass.id, moment(this.date)];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("children.first_name")),
          value: "first_name",
          filter: {
            key: "child.first_name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].child.first_name"],
          },
        },
        {
          text: _.toString(this.$t("children.last_name")),
          value: "last_name",
          filter: {
            key: "child.last_name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].child.last_name"],
          },
        },
        {
          text: _.toString(this.$t("children.birthdate")),
          value: "birthdate",
          filter: {
            key: "child.birthdate",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].child.birthdate"],
          },
        },
        {
          text: _.toString(this.$t("children.status")),
          value: "child_status",
          export: {
            json_paths: ["$.items[*].child_status.name"],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "detail",
          icon: "mdi-magnify",
          default: true,
          onClick: (childAttendance: ChildAttendanceResponse): void => {
            this.child = childAttendance.child;
            this.childDialog = true;
          },
        },
        {
          name: "mark_as_present",
          icon: "mdi-check",
          tooltip: this.$t("children.mark_as_present"),
          color: "success",
          disabled: (childAttendance: ChildAttendanceResponse): boolean => [ChildStatus.CHILD_IN_CLASS.valueOf(), ChildStatus.CHILD_LEFT.valueOf()].includes(childAttendance.child_status.id) || !moment(this.date).isSame(moment(), "day"),
          confirm: (childAttendance: ChildAttendanceResponse): boolean => [ChildStatus.CHILD_APOLOGISED.valueOf(), ChildStatus.CHILD_DOES_NOT_ARRIVE_AT_ALL.valueOf()].includes(childAttendance.child_status.id),
          confirmText: this.$t("children.confirm_mark_as_present"),
          onClick: (childAttendance: ChildAttendanceResponse): Promise<AxiosResponse> => {
            const request: MarkChildAsPresentRequest = { school_class: this.schoolClass.id };
            return ChildAttendanceClient.markChildAsPresent(childAttendance.child.id, moment(this.date), request);
          },
          refetch: true,
        },
        {
          name: "mark_as_missing",
          icon: "mdi-close",
          tooltip: this.$t("children.mark_as_missing"),
          color: "error",
          disabled: (childAttendance: ChildAttendanceResponse): boolean => childAttendance.child_status.id !== ChildStatus.CHILD_IN_CLASS || !moment(this.date).isSame(moment(), "day"),
          confirm: () => true,
          confirmText: this.$t("children.confirm_mark_as_missing"),
          onClick: (childAttendance: ChildAttendanceResponse): Promise<AxiosResponse> => {
            const request: MarkChildAsMissingRequest = { school_class: this.schoolClass.id };
            return ChildAttendanceClient.markChildAsMissing(childAttendance.child.id, moment(this.date), request);
          },
          refetch: true,
        },
      ];
    },
  },
  methods: {
    childStatusColor(childStatus: ChildStatusResponse): string {
      switch (childStatus.id) {
        case ChildStatus.CHILD_APOLOGISED:
          return "info";
        case ChildStatus.CHILD_DOES_NOT_ARRIVE_AT_ALL:
          return "info";
        case ChildStatus.CHILD_DOES_NOT_ARRIVE_YET:
          return "warning";
        case ChildStatus.CHILD_IN_CLASS:
          return "success";
        case ChildStatus.CHILD_LEFT:
          return "info";
        case ChildStatus.CHILD_WAITING_FOR_TEMPORARY_LOCATION_ACCEPTANCE:
          return "warning";
        case ChildStatus.UNKNOWN:
          return "secondary";
        default:
          throw "Invalid child status";
      }
    },
    childStatusIcon(childStatus: ChildStatusResponse): string {
      switch (childStatus.id) {
        case ChildStatus.CHILD_APOLOGISED:
          return "mdi-account-cancel";
        case ChildStatus.CHILD_DOES_NOT_ARRIVE_AT_ALL:
          return "mdi-home";
        case ChildStatus.CHILD_DOES_NOT_ARRIVE_YET:
          return "mdi-account-clock";
        case ChildStatus.CHILD_IN_CLASS:
          return "mdi-account-check";
        case ChildStatus.CHILD_LEFT:
          return "mdi-account-arrow-right";
        case ChildStatus.CHILD_WAITING_FOR_TEMPORARY_LOCATION_ACCEPTANCE:
          return "mdi-account-clock";
        case ChildStatus.UNKNOWN:
          return "mdi-help-circle";
        default:
          throw "Invalid child status";
      }
    },
    onPreviousDay(): void {
      this.date = moment(this.date).subtract(1, "day").format();
    },
    onNextDay(): void {
      this.date = moment(this.date).add(1, "day").format();
    },
    refresh(): void {
      (this.$refs["table"] as any).refresh();
    },
  },
});
